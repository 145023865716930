/* mid size (ipads and tablets */
@media only screen and (max-width: 1200px) {
  .hero-link {
    margin-top: 20px !important;
    margin-bottom: 40px;
  }

  .hero-text-section {
    margin: auto !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr;
    place-items: center;
  }

  .hero-container {
    margin-top: 60px;
  }

  .hero-image-container {
    width: 100%;
  }

  .hero-image {
    display: block !important;
    width: 75% !important;
    margin: 40px auto !important;
    height: 380px !important;
  }
}

/* small sizes (phones */
@media only screen and (max-width: 800px) {
  .hero-text-section {
    margin: auto !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr;
    place-items: center;
  }

  .hero-link {
    margin-top: 20px !important;
    margin-bottom: 70px;
  }
}
