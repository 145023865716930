.p-0,
.p-xs {
  padding: 10px !important;
}
.p-sm {
  padding: 15px;
}
.p-m {
  padding: 20px;
}
.p-l {
  padding: 30px;
}
/* 
.p-x-xs{
    padding-right: @padding-xs;
    padding-left: @padding-xs;
}
.p-x-sm{
    padding-right: @padding-sm;
    padding-left: @padding-sm;
}
.p-x-m{
    padding-right: @padding-md;
    padding-left: @padding-md;
}
.p-x-l{
    padding-right: @padding-lg;
    padding-left: @padding-lg;
}
.p-y-xs{
    padding-top: @padding-xs;
    padding-bottom: @padding-xs;
}
.p-y-sm{
    padding-top: @padding-sm;
    padding-bottom: @padding-sm;
}
.p-y-m{
    padding-top: @padding-md;
    padding-bottom: @padding-md;
}
.p-x-0{
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.p-y-0{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
*/

.p-r-0 {
  padding-right: 0 !important;
}
.p-r-xs {
  padding-right: 10px;
}
.p-r-sm {
  padding-right: 12px;
}
.p-r-m {
  padding-right: 16px;
}
.p-r-l {
  padding-right: 20px;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-l-xs {
  padding-left: 10px;
}
.p-l-sm {
  padding-left: 12px;
}
.p-l-m {
  padding-left: 16px !important;
}
.p-l-l {
  padding-left: 20px;
}

.p-t-0 {
  padding-top: 0 !important;
}
.p-t-xs {
  padding-top: 10px;
}
.p-t-sm {
  padding-top: 12px;
}
.p-t-m {
  padding-top: 16px;
}
.p-t-l {
  padding-top: 20px;
}
.p-t-xl {
  padding-top: 40px;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-xs {
  padding-bottom: 10px;
}
.p-b-sm {
  padding-bottom: 12px;
}
.p-b-m {
  padding-bottom: 16px;
}
.p-b-l {
  padding-bottom: 20px;
}
.p-b-xl {
  padding-bottom: 40px;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-xs {
  margin-bottom: 10px;
}
.m-b-sm {
  margin-bottom: 12px;
}
.m-b-m {
  margin-bottom: 16px;
}
.m-b-l {
  margin-bottom: 20px;
}
.m-b-xl {
  margin-bottom: 30px;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-m {
  margin-top: 20px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-80 {
  margin-top: 80px;
}
