/* mid size (ipads and tablets */
/* @media only screen and (max-width: 1200px) {
  #rectangle-cards {
    height: 800px !important;
  }
} */

/* small sizes (phones */
@media only screen and (max-width: 880px) {
  #rectangle-cards {
    height: 1200px !important;
  }
  .rectangle-cards {
    width: 100% !important;
    margin: auto;
  }
}
