/* mid size (ipads and tablets */
@media only screen and (max-width: 1200px) {
  #scroll-section {
    display: grid !important;
    grid-template-columns: 1fr;
  }
  .scroll-section-title {
    text-align: center;
    width: 80%;
    margin: auto !important;
  }
  .scroll-card-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
  .scroll-card {
    width: 100% !important;
  }
  .scroll-card-text {
    width: 80% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .scroll-cards {
    width: 80%;
    height: 100% !important;
  }
  .scroll-cards:nth-child(1) {
    height: 100% !important;
    margin-top: 135px;
  }
  .scroll-cards:nth-child(2) {
    height: 100% !important;
  }
  .scroll-cards:nth-child(3) {
    height: 100% !important;
    margin-top: 75px;
  }

  .scroll-cards:nth-child(4) {
    height: 100% !important;
    margin-top: 125px;
  }

  .demo-section-container {
    height: 1500px !important;
  }
}

/* small sizes (phones) */
@media only screen and (max-width: 800px) {
  .scroll-card-container {
    grid-template-columns: 1fr;
  }

  .demo-section-container {
    height: 1600px !important;
  }
}
