@media only screen and (max-width: 1200px) {
  .desktop-responsive {
    display: none;
  }

  .mobile-responsive {
    display: block;
  }
  .demo-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .demo-title {
    padding: 0 !important;
  }
  .demo-body-text {
    width: 100% !important;
  }

  .demo-form {
    width: 80% !important;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  .desktop-responsive {
    display: block;
  }

  .mobile-responsive {
    display: none;
  }
}
