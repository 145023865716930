@font-face {
  font-family: "main-font";
  src: local("main-font"),
    url(./tt-norms-pro/TT\ Norms\ Pro\ Black.otf) format("otf");
}
* {
  margin: 0;
}

a {
  color: #6bf4be;
}
body {
  overflow-x: hidden;
}

/* YourComponent.css */

/* Desktop styles */
.desktop-only {
  display: block;
}

/* Mobile styles */
.mobile-only {
  display: none !important;
}

.big-image {
  display: block;
  width: 750px;
  margin: auto !important;
}

@media (max-width: 1200px) {
  /* Adjust styles for mobile screens */
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

  .big-image {
    width: 450px;
  }
}
@media (max-width: 800px) {
  .big-image {
    width: 350px;
  }
}
