@media only screen and (max-width: 1200px) {
  .h-vh {
    height: 100%;
  }
  .font-title {
    font-size: 36px !important;
    text-align: center;
    margin: auto;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .font-title {
    font-size: 36px !important;
    text-align: center;
    margin: auto;
  }
  .h-vh {
    height: 100%;
  }
}
